import { combineReducers } from "redux";
import AboutReducer from "./Reducers/AboutReducer";
import serviceReducer from "./Reducers/ServiceReducer";
import BrandReducer from "./Reducers/BrandReducer";
import ContactReducer from "./Reducers/ContactReducer";
import CompletedReducer from "./Reducers/CompletedReducer";
import UnderReducer from "./Reducers/UnderReducer";

const rootReducer = combineReducers({
  about: AboutReducer,
  service: serviceReducer,
  brand: BrandReducer,
  contact: ContactReducer,
  completed: CompletedReducer,
  under: UnderReducer,
});

export default rootReducer;
