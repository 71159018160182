import React, { Fragment, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Underprojects from "./Underprojects";
import { UnderProjects } from "../../Redux/Actions/Under";

import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

function Under() {
  const dispatch = useDispatch();
  const [size, setSize] = useState(1);
  const { data, loading, totalcount } = useSelector((state) => state.under);
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  useEffect(() => {
    dispatch(UnderProjects(size));
  }, [dispatch, size]);



  const dataCount = Array(totalcount).fill({});

  const swiperRef = useRef(null);

  const handlePrevClick = () => {
    if (size > 1) {
      setSize((prev) => prev - 1);
    }
  };

  const handleNextClick = () => {
    if (size < dataCount.length) {
      setSize((prev) => prev + 1);
    }
  };

  useEffect(() => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.update();
    }
  }, [size]);
  return (
    <Fragment>
      <div className="container-fluid under">
        <div className="row">
          <div className="col-sm-12 ">
            <Swiper
            cssMode={true}
              navigation={true}
              modules={[Navigation]}
              className="mySwiper"
            >
              {loading ? (
                <SwiperSlide>
                  <div>
                    <Spin
                      indicator={antIcon}
                      style={{ color: "#fff", marginTop: "400px" }}
                    />
                  </div>
                </SwiperSlide>
              ) : (
                data?.map((items, index) => (
                  <SwiperSlide key={index}>
                    {items?.UnderConstructionProjectData?.map(
                      (allitems, innerIndex) => (
                        <div key={innerIndex}>
                          <Underprojects data={items} />
                        </div>
                      )
                    )}
                  </SwiperSlide>
                ))
              )}
            </Swiper>
            <div
              className={`swiper-button-prev ${size === 1 ? "disabled" : ""}`}
              onClick={handlePrevClick}
            ><span className="more">Prev Projects</span></div>
            <div
              className={`swiper-button-next ${
                size === dataCount.length ? "disabled" : ""
              }`}
              onClick={handleNextClick}
            >  <span className="more">More Projects</span></div>
          
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Under;
