import React, { Fragment, useEffect } from "react";
import Fade from "react-reveal/Fade";
import { Swiper, SwiperSlide } from "swiper/react";
// import 'swiper/swiper-bundle.css';
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { Service } from "../../Redux/Actions/Service";
import { useDispatch, useSelector } from "react-redux";
import { Skeleton } from "antd";

function Services() {
  const { data, loading } = useSelector((state) => state.service);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(Service());
  }, [dispatch]);

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="container">
          <div className="row services desktop">
            {loading ? (
              <Skeleton active />
            ) : (
              data.length > 0 &&
              data &&
              data?.map((item) => {
                return (
                  <Fade left delay={700}>
                    <div className="col-sm-2 " key={item.id}>
                      <h3>{item.Name}</h3>
                    </div>
                  </Fade>
                );
              })
            )}
          </div>

          {/* mobile services */}
          <div className="row services mobilebrands">
            <Swiper
              slidesPerView={2}
              spaceBetween={30}
              pagination={true}
              modules={[Pagination]}
              className="mySwiper"
              breakpoints={{
                768: {
                  slidesPerView: 3, // Display 3 slides in tab view (when screen width is 768 pixels or more)
                },
              }}
            >
              {data.length > 0 &&
                data &&
                data?.map((item) => (
                  <SwiperSlide key={item.id}>
                    <Fade left delay={700}>
                      <div className="col-sm-2">
                        <h3>{item.Name}</h3>
                      </div>
                    </Fade>
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Services;
