import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  loading: false,
  error: null,
  data: {},

};

const AboutSlice = createSlice({
  name: "about",
  initialState,
  reducers: {
    AboutStart(state, payload) {
      state.loading = true;
      state.error = null;
    
     
    },


    AboutSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data.data;


    
    },

    AboutFail(state, action) {
      state.loading = false;
      state.error = action.payload;

    },
    clearError: (state) => {
      state.error = null;
    },
  
  },
});

export const {
  AboutStart,
  AboutSuccess,

  AboutFail,
  clearError,
 
} = AboutSlice.actions;

export default AboutSlice.reducer;
