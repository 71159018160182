import React, { Fragment } from "react";
import Fade from "react-reveal/Fade";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { About } from "../../Redux/Actions/About";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { Skeleton } from "antd";

function Aboutpage() {
  const dispatch = useDispatch();
  const { data, loading } = useSelector((state) => state.about);


  useEffect(() => {
    dispatch(About());
  }, [dispatch]);
  const paragraphs = data[0]?.Content?.split(".").map((paragraph, index) => (
    <p key={index}>{paragraph.trim()}</p>
  ));




  return (
    <Fragment>
      <div className="container-fluid aboutpage">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 ">
              <Fade left delay={500}>
                {" "}
                <h2>About us</h2>
              </Fade>
              {loading ? (
                <Fade left delay={900}>
                  <p>
                    {" "}
                    <Skeleton active />
                  </p>
                </Fade>
              ) : (
                <Fade left delay={900}>
                  <p>{paragraphs} </p>
                </Fade>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Aboutpage;
