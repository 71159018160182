import React, { Fragment, useState, useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import CompleteProjets from "./CompleteProjets";
import { useDispatch, useSelector } from "react-redux";
import { CompletedProjects } from "../../Redux/Actions/Completed";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";


const Complete = () => {
  const dispatch = useDispatch();
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  const { data, loading, totalcount } = useSelector((state) => state.completed);
  const [size, setSize] = useState(1);

  useEffect(() => {
    dispatch(CompletedProjects(size));
  }, [size]);



  const dataCount = Array(totalcount).fill({});

  const swiperRef = useRef(null);

  const handlePrevClick = () => {
    if (size > 1) {
      setSize((prev) => prev - 1);
    }
  };

  const handleNextClick = () => {
    if (size < dataCount.length) {
      setSize((prev) => prev + 1);
    }
  };

  useEffect(() => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.update();
    }
  }, [size]);



  return (
    <Fragment>
      <div className="container-fluid under" >
        <div className="row">
          <div className="col-sm-12 ">
            <Swiper
            cssMode={true}
            modules={[Navigation]} className="mySwiper" ref={swiperRef}>
              {loading ? (
                <SwiperSlide>
                  <div>
                    <Spin
                      indicator={antIcon}
                      style={{ color: "#fff", marginTop: "400px" }}
                    />
                  </div>
                </SwiperSlide>
              ) : (
                dataCount.map((_, index) => (
                  <SwiperSlide key={index}>
                    {data && data[index]?.CompletedProjectData ? (
                      <CompleteProjets data={data[index]} />
                    ) : null}
                  </SwiperSlide>
                ))
              )}
            </Swiper>
            <div
              className={`swiper-button-prev ${size === 1 ? "disabled" : ""}`}
              onClick={handlePrevClick}
            ><span className="more">Prev Projects</span></div>
            <div
              className={`swiper-button-next ${
                size === dataCount.length ? "disabled" : ""
              }`}
              onClick={handleNextClick}
            ><span className="more">More Projects</span></div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Complete;