import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  completedStart,
  completedSuccess,
  completedFail,
} from "../Reducers/CompletedReducer";

export const CompletedProjects = createAsyncThunk(
  "completed",
  async (size, thunkAPI) => {
    try {
      thunkAPI.dispatch(completedStart()); // Dispatch the start action
      // Make your API request here, e.g., using fetch or axios
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/GetCompletedProjectsV2?page=${size}`
      );

      thunkAPI.dispatch(completedSuccess(response));

      return response;
    } catch (error) {
      console.error(error);

      thunkAPI.dispatch(completedFail(error.response.data.message));
    }
  }
);