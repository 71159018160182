import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";


import { serviceStart, serviceSuccess, serviceFail } from "../Reducers/ServiceReducer";

export const Service = createAsyncThunk(
  "service",
  async (_, thunkAPI) => {
    try {

      thunkAPI.dispatch(serviceStart()); // Dispatch the start action
      // Make your API request here, e.g., using fetch or axios
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/GetServicesListing?size=100`,
      );
  
  
      thunkAPI.dispatch(serviceSuccess(response));

  
      return response;
    } catch (error) {
      console.error(error)
    
      thunkAPI.dispatch(serviceFail(error.response.data.message)); 
    }
  },
);
