import React, { Fragment } from "react";
import Fade from "react-reveal/Fade";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import Services from "./Services";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Brand } from "../../Redux/Actions/Brand";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Skeleton } from "antd";

function ServicePage() {
  const { data, loading } = useSelector((state) => state.brand);

  const dispatch = useDispatch();
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  useEffect(() => {
    dispatch(Brand());
  }, [dispatch]);





  return (
    <Fragment>
      <div className="container-fluid servicepage" key={data.key}>
        <div className="container">
          <div className="row services ">
            <div className="col-sm-12 ">
              <Fade left delay={500}>
                <h2>Services</h2>
              </Fade>
            </div>
          </div>

          {/* mobile services */}

          <Services />

          <div className="row partners">
            <div className="col-sm-12 ">
              <Fade up delay={900}>
                <h2>Brands we are associated with</h2>
              </Fade>
            </div>

            <div className="brands row desktop">
              {loading ? (
                <Skeleton active />
              ) : (
                data?.map((items) => (
                  <Fade key={items.id} up delay={1100}>
                    <div className="col-sm-2 desktop" key={items.id}>
                      {items.image ? (
                        <img
                          src={
                            process.env.REACT_APP_DYNAMIC_ASSETS_URL +
                            items.image
                          }
                          alt={`Brand: ${items.name}`}
                        />
                      ) : (
                        // <Spin indicator={antIcon} style={{ color: "#fff" }} />
                        <div
                          className="spinloadss service"
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                          }}
                        >
                          <Spin size="large" className="spinloads" />
                        </div>
                      )}
                    </div>
                  </Fade>
                ))
              )}
            </div>

            {/* mobile brands */}
            <div className="brands row mobilebrands">
              <Swiper
                slidesPerView={3}
                spaceBetween={30}
                pagination={true}
                modules={[Pagination]}
                className="mySwiper"
              >
                {data?.map((items) => {
                  return (
                    <div className="mobilebrands">
                      <SwiperSlide>
                        <Fade up delay={1100}>
                          <div className="col-sm-2" key={items.id}>
                            <img
                              src={
                                process.env.REACT_APP_DYNAMIC_ASSETS_URL +
                                items.image
                              }
                              alt="Partners"
                            />
                          </div>
                        </Fade>
                      </SwiperSlide>
                    </div>
                  );
                })}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default ServicePage;
