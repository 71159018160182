import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";


import { contactStart, contactSuccess, contactFail } from "../Reducers/ContactReducer";

export const Contact = createAsyncThunk(
  "contact",
  async (data, thunkAPI) => {
    try {

      thunkAPI.dispatch(contactStart()); // Dispatch the start action
      // Make your API request here, e.g., using fetch or axios
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/CreateQuoteLead`,
      data);
  
  
      thunkAPI.dispatch(contactSuccess(response));

  
      return response;
    } catch (error) {
      console.error(error)
    
      thunkAPI.dispatch(contactFail(error.response.data.message)); 
    }
  },
);
