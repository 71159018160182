import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  loading: false,
  error: null,
  data: [],

};

const BrandSlice = createSlice({
  name: "brand",
  initialState,
  reducers: {
    brandStart(state, payload) {
      state.loading = true;
      state.error = null;
    
     
    },


    brandSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data.data


    
    },

    brandFail(state, action) {
      state.loading = false;
      state.error = action.payload;

    },
    clearError: (state) => {
      state.error = null;
    },
  
  },
});

export const {
  brandStart,
  brandSuccess,

  brandFail,
  clearError,
 
} = BrandSlice.actions;

export default BrandSlice.reducer;
