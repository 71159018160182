export const homData = [
    {
        key: "1",
        heading: "Alpago contracting",
        tagline: "design and build",
        image: "../Assets/homebg.png"
    },
]

export const aboutData = [
    {
        key: "1",
        heading: "About Us",
        tagline: "Alpago Properties is a fully owned subsidiary of Alpago Group. The company has started its journey in Istanbul and London by developing high-end residential, commercial, and hospitality projects. The vision of the company is to set new global benchmarks within the ultra-high-end real estate segment by partnering with the best architects, interior designers, builders, artists, and artisans over the world. Alpago Properties is now headquartered in Dubai and is dedicated on building architectural masterpieces while creating distinguished lifestyles and experiences for each project. ",
        image: "../Assets/aboutbg.png"
    },
]

export const QouteData = [
    {
        key: "1",
        heading: "Get A Quote",
       image: "../Assets/Get-a-Quote.png"
    },
]

export const serviceData = [
    {
        key: "1",
        heading: "Services",
        brandheading: "Brands we are associated with",
        image: "../Assets/services.png",
        Services: [
            {
                id: "1",
                service: 'Burglary and theft',
            },
            {
                id: "2",
                service: 'Conceptual design	',
            },
            {
                id: "3",
                service: 'Detailed design',
            },
            {
                id: "4",
                service: 'Construction ',
            },
            {
                id: "5",
                service: 'Fit out',
            },
            {
                id: "6",
                service: 'Landscaping',
            },
            {
                id: "7",
                service: 'MEP',
            },
            {
                id: "8",
                service: 'Maintenance',
            },
            {
                id: "9",
                service: 'Kitchen design',
            },
            {
                id: "10",
                service: 'Wardrobe design',
            },
            {
                id: "11",
                service: 'Furniture ',
            },
            {
                id: "12",
                service: 'Joinery works ',
            },
            {
                id: "13",
                service: 'Turnkey solutions',
            },
            {
                id: "14",
                service: 'Home renovations',
            },
            {
                id: "14",
                service: 'Refurbishment',
            },
        ],
        Brands: [
            {
                id: "1",
                brandpic: "../Assets/new/B&B.png"
            },
            {
                id: "2",
                brandpic: "../Assets/new/Bang-Olufsen-Logo.png"
            },
            {
                id: "3",
                brandpic: "../Assets/new/Baxter.png"
            },
            {
                id: "4",
                brandpic: "../Assets/new/boca.png"
            },
            {
                id: "5",
                brandpic: "../Assets/new/exteta.png"
            },
            {
                id: "6",
                brandpic: "../Assets/new/giorgeeta.png"
            },
            {
                id: "7",
                brandpic: "../Assets/new/Henge.png"
            },
            {
                id: "8",
                brandpic: "../Assets/new/laborgini.png"
            },
            {
                id: "9",
                brandpic: "../Assets/new/Minotti.png"
            },
            {
                id: "10",
                brandpic: "../Assets/new/poliform.png"
            },
            {
                id: "11",
                brandpic: "../Assets/new/Roberto-Cavalli.png"
            },
            {
                id: "12",
                brandpic: "../Assets/new/Royal-Botania.png"
            },

        ],
    },
]

export const ServicesAll = [
    {
        id: "1",
        service: 'Burglary and theft',
    },
    {
        id: "2",
        service: 'Conceptual design	',
    },
    {
        id: "3",
        service: 'Detailed design',
    },
    {
        id: "4",
        service: 'Construction ',
    },
    {
        id: "5",
        service: 'Fit out',
    },
    {
        id: "6",
        service: 'Landscaping',
    },
    {
        id: "7",
        service: 'MEP',
    },
    {
        id: "8",
        service: 'Maintenance',
    },
    {
        id: "9",
        service: 'Kitchen design',
    },
    {
        id: "10",
        service: 'Wardrobe design',
    },
    {
        id: "11",
        service: 'Furniture ',
    },
    {
        id: "12",
        service: 'Joinery works ',
    },
    {
        id: "13",
        service: 'Turnkey solutions',
    },
    {
        id: "14",
        service: 'Home renovations',
    },
    {
        id: "14",
        service: 'Refurbishment',
    },
]


export const mediaItems = [

    {
        key:"1",
        slide:[
            {
                media: {
                    type: 'image',
                    content: '../Assets/1.png',
                    title: 'Project 4 Completed',
                    description: 'Description for Project 4',
                    thumbnail: '../Assets/1.png',
                },
         
            },
            
            {
                media: {
                    type: 'image',
                    content: '../Assets/2.png',
                    title: 'Project 2 Completed',
                    description: 'Description for Project 2',
                    thumbnail: '../Assets/2.png',
                },
                
            },  
            {
                media: {
                    type: 'video',
                    content: 'https://www.youtube.com/watch?v=4kT-lYsr05U', // Video link
                    title: 'Project 3 Completed',
                    description: 'Description for Project 3',
                    thumbnail: '../Assets/1.png',
                },
              
            },  
            {
                media: {
                    type: 'image',
                    content: '../Assets/2.png',
                    title: 'Project 1 Completed',
                    description: 'Description for Project 1',
                    thumbnail: '../Assets/2.png',
                },
             
            },
          
            {
                media: {
                    type: 'video',
                    content: 'https://www.youtube.com/watch?v=4kT-lYsr05U', // Video link
                    title: 'Project 5 Completed',
                    description: 'Description for Project 5',
                    thumbnail: '../Assets/1.png'
                },
                
            },
        ]
    },

    {
        key:"2",
        slide:[
            {
                media: {
                    type: 'image',
                    content: '../Assets/1.png',
                    title: 'Project 4 Completed',
                    description: 'Description for Project 4',
                    thumbnail: '../Assets/1.png',
                },
         
            },
            
            {
                media: {
                    type: 'image',
                    content: '../Assets/2.png',
                    title: 'Project 2 Completed',
                    description: 'Description for Project 2',
                    thumbnail: '../Assets/2.png',
                },
                
            },  
            {
                media: {
                    type: 'video',
                    content: 'https://www.youtube.com/watch?v=4kT-lYsr05U', // Video link
                    title: 'Project 3 Completed',
                    description: 'Description for Project 3',
                    thumbnail: '../Assets/1.png',
                },
              
            },  
            {
                media: {
                    type: 'image',
                    content: '../Assets/2.png',
                    title: 'Project 1 Completed',
                    description: 'Description for Project 1',
                    thumbnail: '../Assets/2.png',
                },
             
            },
          
            {
                media: {
                    type: 'video',
                    content: 'https://www.youtube.com/watch?v=4kT-lYsr05U', // Video link
                    title: 'Project 5 Completed',
                    description: 'Description for Project 5',
                    thumbnail: '../Assets/1.png'
                },
                
            },
        ]
    },

    {
        key:"3",
        slide:[
            {
                media: {
                    type: 'image',
                    content: '../Assets/1.png',
                    title: 'Project 4 Completed',
                    description: 'Description for Project 4',
                    thumbnail: '../Assets/1.png',
                },
         
            },
            
            {
                media: {
                    type: 'image',
                    content: '../Assets/2.png',
                    title: 'Project 2 Completed',
                    description: 'Description for Project 2',
                    thumbnail: '../Assets/2.png',
                },
                
            },  
            {
                media: {
                    type: 'video',
                    content: 'https://www.youtube.com/watch?v=4kT-lYsr05U', // Video link
                    title: 'Project 3 Completed',
                    description: 'Description for Project 3',
                    thumbnail: '../Assets/1.png',
                },
              
            },  
            {
                media: {
                    type: 'image',
                    content: '../Assets/2.png',
                    title: 'Project 1 Completed',
                    description: 'Description for Project 1',
                    thumbnail: '../Assets/2.png',
                },
             
            },
          
            {
                media: {
                    type: 'video',
                    content: 'https://www.youtube.com/watch?v=4kT-lYsr05U', // Video link
                    title: 'Project 5 Completed',
                    description: 'Description for Project 5',
                    thumbnail: '../Assets/1.png'
                },
                
            },
        ]
    },
  
    
  ];