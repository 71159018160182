import { Fragment, useState, useEffect } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./Style/Style.css"
import "./Style/responsive.css"
import Home from './Layout/Home';
import Header from './Components/Header';
import Loader from './Components/Loader/Loader';
import AOS from 'aos';
import 'aos/dist/aos.css';

function App() {
  const [loading, setLoading] = useState(false);



  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 4000)
  }, [])

  useEffect(() => {
    AOS.init({
      duration: 1200,
      easing: 'ease-in-out-back'
    });
  }, [])


  useEffect(() => {
    console.clear();
  }, []);


  return (
    <Fragment>
      <>
        <Header />
        <Home />
      </>
    </Fragment>
  );
}

export default App;
