import React, { Fragment } from 'react'

import Flip from "react-reveal/Flip";


function Loader() {
  return (
    <Fragment>
      <div className="container-fluid">
        <div
          className="row preloader "
          data-aos="fade-up"
          data-aos-duration="2500"
        >
          <div className="imagepre col-sm-12 ">
            <img
              src={`${process.env.REACT_APP_ASSETS_URL}/Logo.png`}
              alt="preloader"
              data-aos="fade-down"
              data-aos-duration="2000"
              className="zoom-in-zoom-outa"
            />
            <div>
              <h2 data-aos="fade-down" data-aos-duration="2000">
                <Flip top delay={500}>
                  We Construct Your Dream
                </Flip>
                <Flip top delay={800}>
                  
                </Flip>
              </h2>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Loader