import React, { Fragment } from 'react'
import { Button, Form, Input, InputNumber } from 'antd';
import { QouteData } from "../../Data/Data"
import Fade from "react-reveal/Fade";
import { useDispatch, useSelector } from "react-redux";
import { Contact } from "../../Redux/Actions/Contact";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { clearError, clearMessage } from "../../Redux/Reducers/ContactReducer";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};
/* eslint-enable no-template-curly-in-string */
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);

function GetaQoute() {
  const { message, error, loading } = useSelector((state) => state.contact);

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const onFinish = (values) => {


    dispatch(Contact(values));
    form.resetFields();
  };

  useEffect(() => {
    if (message) {
      toast.success(message);
      dispatch(clearMessage());
    }
    if (error) {
      toast.error(error);
      dispatch(clearError());
    }
  }, [dispatch, error, message]);






  return (
    <Fragment>
      {QouteData.map((data) => {

        return (
          <div
            className="container-fluid aboutpage contactpage"
          >
            <div className="container">
              <div className="row">
                <div className="col-sm-12 ">
                  <Fade left delay={500}>
                    <h2>{data.heading}</h2>
                  </Fade>
                </div>

                <div className="col-sm-6">
                  <Form
                    name="nest-messages"
                    onFinish={onFinish}
                    validateMessages={validateMessages}
                    form={form}
                  >
                    <Fade down delay={500}>
                      <Form.Item name="Name" rules={[{ required: true }]}>
                        <Input placeholder="Name" />
                      </Form.Item>
                    </Fade>

                    <Fade down delay={800}>
                      <Form.Item
                        name="Email"
                        rules={[{ required: true, type: "email" }]}
                      >
                        <Input placeholder="E-mail" />
                      </Form.Item>
                    </Fade>

                    <Fade down delay={1100}>
                      <Form.Item
                        name="Contact"
                        rules={[
                          {
                            required: true,
                            pattern: /^[0-9]+$/,
                            message: 'Please enter a valid phone number with no alphabets',
                          },
                        ]}
                      >
                        <Input placeholder="Contact" />
                      </Form.Item>
                    </Fade>

                    <Fade down delay={1400}>
                      <Form.Item name="Message" rules={[{ required: true }]}>
                        <Input.TextArea placeholder="Message" />
                      </Form.Item>
                    </Fade>

                    <Fade down delay={1800}>
                      <Form.Item>
                        <Button type="primary" htmlType="submit">
                          {" "}
                          {loading ? (
                            <Spin
                              indicator={antIcon}
                              style={{ color: "#fff" }}
                            />
                          ) : (
                            "Submit"
                          )}
                        </Button>
                      </Form.Item>
                    </Fade>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </Fragment>
  );
}

export default GetaQoute