import React, { useEffect, useRef, useState } from 'react';
import Hls from 'hls.js';

function VideoPlayer({ link }) {
  const videoRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const video = videoRef.current;
    let hls;

    const handleVisibilityChange = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      });
    };

    const observer = new IntersectionObserver(handleVisibilityChange, { threshold: 0.5 });

    if (video) {
      observer.observe(video);

      if (Hls.isSupported()) {
        hls = new Hls();
        hls.loadSource(`${process.env.REACT_APP_DYNAMIC_ASSETS_URL}${link}`);
        hls.attachMedia(video);
      } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = `${process.env.REACT_APP_DYNAMIC_ASSETS_URL}${link}`;
      }

      return () => {
        observer.disconnect();
        if (hls) {
          hls.destroy();
        }
      };
    }
  }, [link]);

  useEffect(() => {
    if (videoRef.current && !isVisible) {
      videoRef.current.pause();
    }
  }, [isVisible]);

  return (
    <div>
      <video ref={videoRef} controls />
    </div>
  );
}

export default VideoPlayer;