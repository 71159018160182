import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  loading: false,
  error: null,
  data: [],

};

const ServiceSlice = createSlice({
  name: "service",
  initialState,
  reducers: {
    serviceStart(state, payload) {
      state.loading = true;
      state.error = null;
    
     
    },


    serviceSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data.data


    
    },

    serviceFail(state, action) {
      state.loading = false;
      state.error = action.payload;

    },
    clearError: (state) => {
      state.error = null;
    },
  
  },
});

export const {
  serviceStart,
  serviceSuccess,

  serviceFail,
  clearError,
 
} = ServiceSlice.actions;

export default ServiceSlice.reducer;
