import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  data: [],
  totalcount: null,
};

const UnderSlice = createSlice({
  name: "under",
  initialState,
  reducers: {
    underStart(state) {
      state.loading = true;
      state.error = null;
      state.totalcount = null;
    },

    underSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data.data;
      state.totalcount = action.payload.data.totalcount;
    },

    underFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = null;
    },
  },
});

export const {
  underStart,
  underSuccess,

  underFail,
  clearError,
} = UnderSlice.actions;

export default UnderSlice.reducer;
