import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { underStart, underSuccess, underFail } from "../Reducers/UnderReducer";

export const UnderProjects = createAsyncThunk(
  "under",
  async (size, thunkAPI) => {
    try {
      thunkAPI.dispatch(underStart()); // Dispatch the start action
      // Make your API request here, e.g., using fetch or axios
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/GetUnderConstructionProjectsListing?size=1&page=${size}`
      );

      thunkAPI.dispatch(underSuccess(response));

      return response;
    } catch (error) {
      console.error(error);

      thunkAPI.dispatch(underFail(error.response.data.message));
    }
  }
);
