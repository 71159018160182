import React, { Fragment, useState } from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import Fade from "react-reveal/Fade";
import VideoPlayer from '../VideoPlayer';
import ReactImageAppear from "react-image-appear";
import { Button, Image, Spin  } from 'antd'

function MediaItem(data) {
  
  const [loading, setLoading] = useState(true);

  const handleImageLoaded = () => {
    setLoading(false);
  };

  const handleImageError = () => {
    // Handle image loading error here
    setLoading(false);
  };


  if (data.type && data.type.split("/")[0] === "image") {
    return (
      <div style={{ position: 'relative', }}>
      {loading && (
        <div className='spinloadss'  style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
          <Spin size="large" className='spinloads'/>
        </div>
      )}
      
      <Image
        className="slideimg1"
        src={`${process.env.REACT_APP_DYNAMIC_ASSETS_URL}${data.media}`}
        alt="First slide"
        style={loading ? { visibility: 'hidden' } : { visibility: 'visible' }}
        onLoad={handleImageLoaded}
        onError={handleImageError}
      />
      <h2>{data.title}</h2>
    </div>
    );
  } else {
    return <VideoPlayer link={data.media} />;
  }
}

function Underprojects(data) {
  const thumbnail = "https://media.gettyimages.com/id/472605657/video/play-button-blue.jpg?s=640x640&k=20&c=JCyZi7XOAEwiGmpM5tTZshDwLIQidiHnCQX5wIYHnc0=";
  const items = data?.data?.UnderConstructionProjectData?.map((item) => ({
    original:
      item?.type.split("/")[0] == "image" ? `${process.env.REACT_APP_DYNAMIC_ASSETS_URL}${data?.data?.Name}` : thumbnail,
    thumbnail: item?.type.split("/")[0] == "image" ? `${process.env.REACT_APP_DYNAMIC_ASSETS_URL}${item?.image}` : thumbnail,

    type: data?.type,

    title: data?.Name,
    renderItem: () => (
      <MediaItem media={item.image} title={data?.data?.Name} type={item.type} />
    ),
  }));

  return (
    <Fragment>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 myprojects">
            <Fade up delay={800}>
              <ImageGallery items={items} showPlayButton={false} />
            </Fade>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Underprojects;