import React, { Fragment, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';

import Homepage from '../Components/Home/Homepage';
import Aboutpage from '../Components/About/Aboutpage';
import Under from '../Components/Under Construction/Under';
import Complete from '../Components/Project Completed/Complete';
import ServicePage from '../Components/Services/ServicePage';
import GetaQoute from '../Components/Qoute/GetaQoute';

function Home() {
  const swiperRef = useRef(null);
  const [activeSlide, setActiveSlide] = useState(0);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [activeIndex, setActiveIndex] = useState(null);

  const handleSlideChange = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      setActiveSlide(swiperRef.current.swiper.realIndex);
    }
  };

  const goToSlide = (slideIndex) => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo(slideIndex);
    }
  };

  const handleOffcanvasItemClick = (slideIndex) => {
    // Close the Offcanvas and go to the selected slide
    handleClose();
    goToSlide(slideIndex);
    
  };

 
  const handleItemClick = (index) => {
    setActiveIndex(index);
    goToSlide(index); // Assuming goToSlide is a defined function
  };

  const backGroundHome = `${process.env.REACT_APP_ASSETS_URL}/homebg.png`;
  const backGroundStyling = {
    backgroundImage: `url(${backGroundHome})`,
    backgroundSize: "100% 100%",
    backgroundPosition: "center",
  };

  const backGroundAbout = `${process.env.REACT_APP_ASSETS_URL}/aboutbg.png`;
  const backGroundStylingabout = {
    backgroundImage: `url(${backGroundAbout})`,
    backgroundSize: "100% 100%",
    backgroundPosition: "center",
  };

  const backGroundUnder = `${process.env.REACT_APP_ASSETS_URL}/underbg.png`;
  const backGroundStylingUnder = {
    backgroundImage: `url(${backGroundUnder})`,
    backgroundSize: "100% 100%",
    backgroundPosition: "center",
  };

  const backGroundComplete = `${process.env.REACT_APP_ASSETS_URL}/Completed-Projects.png`;
  const backGroundStylingComplete = {
    backgroundImage: `url(${backGroundComplete})`,
    backgroundSize: "100% 100%",
    backgroundPosition: "center",
  };

  const backGroundService = `${process.env.REACT_APP_ASSETS_URL}/services.png`;
  const backGroundStylingService = {
    backgroundImage: `url(${backGroundService})`,
    backgroundSize: "100% 100%",
    backgroundPosition: "center",
  };

  const backGroundQoute = `${process.env.REACT_APP_ASSETS_URL}/Get-a-Quote.png`;
  const backGroundStylingQoute = {
    backgroundImage: `url(${backGroundQoute})`,
    backgroundSize: "100% 100%",
    backgroundPosition: "center",
  };

  return (
    <Fragment>
      <div className="mySwiper">
        <Swiper
          direction={'vertical'}
          autoHeight={true}
          pagination={{
            clickable: true,
          }}
          className="mySwiper zoom-in-zoom-out"
          ref={swiperRef}
          onSlideChange={handleSlideChange}
          speed={1000} 
        >
          <SwiperSlide style={backGroundStyling}>
            {activeSlide === 0 && (
              
                <Homepage />
           
            )}
          </SwiperSlide>
          <SwiperSlide style={backGroundStylingabout}>
            {activeSlide === 1 && (
           
                <Aboutpage />
          
            )}
          </SwiperSlide>
          <SwiperSlide style={backGroundStylingUnder}>
            {activeSlide === 2 && (
            
                <Under />
           
            )}
          </SwiperSlide>
          <SwiperSlide style={backGroundStylingComplete}>
            {activeSlide === 3 && (
              
                <Complete />
             
            )}
          </SwiperSlide>
          <SwiperSlide style={backGroundStylingService}>
            {activeSlide === 4 && (
           
                <ServicePage />
         
            )}
          </SwiperSlide>
          <SwiperSlide style={backGroundStylingQoute}>
            {activeSlide === 5 && (
          
                <GetaQoute />
             
            )}
          </SwiperSlide>
        </Swiper>

        <Fade up delay={800}>
          <button
            onClick={() => {
              if (activeSlide > 0) {
                goToSlide(activeSlide - 1);
              }
            }}
            className="prevslide"
            style={{ display: activeSlide === 0 ? 'none' : 'block' }}
          >
            <div className="swiper-button-next"></div>
          </button>
        </Fade>

        <Fade down delay={800}>
          <button
            onClick={() => {
              if (activeSlide !== 5) {
                goToSlide(activeSlide + 1);
              }
            }}
            className="nextslide"
            style={{ display: activeSlide === 5 ? 'none' : 'block' }}
          >
            <div className="swiper-button-next"></div>
          </button>
        </Fade>

      </div>

      <div className="container-fluid footer">
        <div className="row">
          <ul>
            <Flip top delay={700}>
              <li onClick={() => handleItemClick(0)} className={activeIndex === 0 ? 'active' : ''}>Home</li>
            </Flip>
            <Flip top delay={900}>
              <li  onClick={() => handleItemClick(1)} className={activeIndex === 1 ? 'active' : ''}>About Us</li>
            </Flip>
            <Flip top delay={1100}>
              <li  onClick={() => handleItemClick(3)} className={activeIndex === 3 ? 'active' : ''}>Completed Projects</li>
            </Flip>
            <Flip top delay={1300}>
              <li  onClick={() => handleItemClick(2)} className={activeIndex === 2 ? 'active' : ''}>Under Construction</li>
            </Flip>
            <Flip top delay={1500}>
              <li  onClick={() => handleItemClick(4)} className={activeIndex === 4 ? 'active' : ''}>Services</li>
            </Flip>
            <Flip top delay={1700}>
              <li  onClick={() => handleItemClick(5)} className={activeIndex === 5 ? 'active' : ''}>Get a Quote</li>
            </Flip>
          </ul>
        </div>
      </div>

      <div className='mobilemenu'>
      <Button variant="primary" onClick={handleShow}>
        Menu
      </Button>

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
        <Flip top delay={500}>
          <Offcanvas.Title>ALPAGO Construction</Offcanvas.Title>
          </Flip>
        </Offcanvas.Header>
        <Offcanvas.Body>
        <ul>
            <Flip top delay={700}>
              <li onClick={() => handleOffcanvasItemClick(0)}>Home</li>
            </Flip>
            <Flip top delay={900}>
              <li onClick={() => handleOffcanvasItemClick(1)}>About Us</li>
            </Flip>
            <Flip top delay={1100}>
              <li onClick={() => handleOffcanvasItemClick(3)}>Completed Projects</li>
            </Flip>
            <Flip top delay={1300}>
              <li onClick={() => handleOffcanvasItemClick(2)}>Under Construction</li>
            </Flip>
            <Flip top delay={1500}>
              <li onClick={() => handleOffcanvasItemClick(4)}>Services</li>
            </Flip>
            <Flip top delay={1700}>
              <li onClick={() => handleOffcanvasItemClick(5)}>Get a Quote</li>
            </Flip>
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
      </div>
    </Fragment>
  );
}

export default Home;