import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";


import { brandStart, brandSuccess, brandFail } from "../Reducers/BrandReducer";

export const Brand = createAsyncThunk(
  "brand",
  async (_, thunkAPI) => {
    try {

      thunkAPI.dispatch(brandStart()); // Dispatch the start action
      // Make your API request here, e.g., using fetch or axios
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/GetBrandListing?size=100`,
      );
  
  
      thunkAPI.dispatch(brandSuccess(response));

  
      return response;
    } catch (error) {
      console.error(error)
    
      thunkAPI.dispatch(brandFail(error.response.data.message)); 
    }
  },
);
