import React, { Fragment } from "react";
import Fade from "react-reveal/Fade";

function Header() {
  return (
    <Fragment>
      <div className="container-fluid logo">
        <div className="row">
          <div className="col-sm-12">
            <Fade down delay={500}>
              {" "}
              {/* <img
                src={`${process.env.REACT_APP_ASSETS_URL}/Logo.png`}
                alt="Logo"
              /> */}
              <img src="/FINAL LOGO -05.png" alt="Logo" width={"8%"} />
            </Fade>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Header;
