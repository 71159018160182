import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  data: [],
  totalcount: null,
};

const CompletedSlice = createSlice({
  name: "completed",
  initialState,
  reducers: {
    completedStart(state, payload) {
      state.loading = true;
      state.error = null;
      state.totalcount = null;
    },

    completedSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data.data;
      state.totalcount = action.payload.data.totalcount;
    },

    completedFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = null;
    },
  },
});

export const {
  completedStart,
  completedSuccess,

  completedFail,
  clearError,
} = CompletedSlice.actions;

export default CompletedSlice.reducer;