import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  loading: false,
  error: null,
message:null

};

const ContactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {
    contactStart(state) {
      state.loading = true;
      
     
    },


    contactSuccess(state, action) {
      state.loading = false;
      state.message="Query Submitted Successfully"
    },

    contactFail(state, action) {
      state.loading = false;
      state.error = action.payload;
      
      

    },
    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  

  },
});

export const {
  contactStart,
  contactSuccess,
  clearMessage,
  contactFail,
  clearError,
 
} = ContactSlice.actions;

export default ContactSlice.reducer;
