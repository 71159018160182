import React, { Fragment, useEffect } from "react";
import "swiper/swiper-bundle.css";
import Flip from "react-reveal/Flip";
import { useDispatch, useSelector } from "react-redux";
import { About } from "../../Redux/Actions/About";

function Homepage() {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.about);

  useEffect(() => {
    dispatch(About());
  }, [dispatch]);
  const heading = data[1]?.Content?.split(".").map((paragraph, index) => (
    <h2 key={index}>{paragraph.trim()}</h2>
  ));
  const paragraphs = data[1]?.Category?.split(".").map((paragraph, index) => (
    <p key={index}>{paragraph.trim()}</p>
  ));


  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row" key={data.key}>
          <div className="col-sm-12 homepage home" >
            <Flip top delay={1500}>
              {" "}
              <h2>{heading}</h2>
            </Flip>

            <Flip top delay={1800}>
              {" "}
              <p>{paragraphs}</p>
            </Flip>
          </div>
        </div>
      </div>
      {/* </Fade> */}
    </Fragment>
  );
}

export default Homepage;
